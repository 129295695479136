<template>
  <div id="layout-wrapper" class="d-flex flex-column align-center">
    <div class="header-wrapper">
      <span>BILAG Testing App</span>
    </div>
    <div class="body-wrapper d-flex align-center flex-column">
      <slot />
    </div>
    <div class="footer-wrapper"></div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#layout-wrapper {
  width: 100%;
  .header-wrapper {
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    background: teal;
    color: white;
    z-index: 999;
  }
  .body-wrapper {
    width: 100%;
    padding-top: 60px;
  }
  .footer-wrapper {
    padding-top: 50px;
  }
}
</style>
