<template>
  <div class="bilagform-wrapper d-flex">
    <details open>
      <summary class="details">{{ bilag.category }}</summary>
      <table :id="`tb-${index}`">
        <thead v-if="index < 7">
          <tr>
            <th colspan="7">
              {{ bilag.category }}
            </th>
          </tr>
          <tr>
            <td class="question-td"></td>
            <td v-for="unit in units" :key="unit" class="unit-td">
              {{ unit }}
            </td>
            <td class="unit-td">
              previously involved
            </td>
          </tr>
        </thead>
        <thead v-else-if="index === 7">
          <tr>
            <th colspan="5">
              {{ bilag.category }}
            </th>
          </tr>
          <tr>
            <td class="question-td"></td>
            <td colspan="2">Now</td>
            <td colspan="2">4 Weeks Ago</td>
            <td class="unit-td">
              previously involved
            </td>
          </tr>
        </thead>
        <thead v-else>
          <tr>
            <th colspan="7">
              {{ bilag.category }}
            </th>
            <td class="unit-td">
              previously involved
            </td>
          </tr>
        </thead>
        <tbody v-if="index < 7">
          <tr v-for="(question, qindex) in bilag.question" :key="qindex">
            <td>
              {{ question.text }}
              <v-tooltip v-if="question.description !== null" bottom>
                <template #activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
                </template>
                <div v-for="desc in question.description" :key="desc">
                  {{ desc }}
                </div>
                <img
                  v-if="index === 1 && qindex === 0"
                  src="../assets/rule_of_nines.png"
                  width="400px"
                  height="400px"
                />
              </v-tooltip>
            </td>
            <td v-for="answer in getType(question.type)" :key="answer" :colspan="6 / getType(question.type)">
              <input
                v-if="question.type === 0"
                class="radioInput"
                type="radio"
                :value="answer === 6 ? -1 : answer - 1"
                :id="`${index}-${qindex}-${answer - 1}`"
                :name="`${index}-${qindex}`"
                :checked="answer === 1"
              />
              <div v-else-if="question.type === 1" class="d-flex flex-row justify-center align-center">
                <input
                  class="yesnoInput"
                  type="radio"
                  :value="answer - 1"
                  :id="`${index}-${qindex}-${answer - 1}`"
                  :name="`${index}-${qindex}`"
                  :checked="answer === 1"
                />
                <label :for="`${index}-${qindex}-${answer - 1}`">{{ answer % 2 ? 'No' : 'Yes' }}</label>
              </div>
              <v-text-field
                :id="`${index}-${qindex}-${answer - 1}-0`"
                style="margin-right: 24px"
                v-else-if="question.type === 2"
                type="number"
                value="-1"
                dense
                hide-details
                outlined
                :suffix="question.unit"
                :rules="[rules.positive]"
              ></v-text-field>
              <!-- <p class="pDiv d-flex flex-row">
                  <input
                    class="input-field"
                    type="number"
                    value="0"
                    :id="`${index}-${qindex}-${answer - 1}-0`"
                    :name="`${index}-${qindex}-${answer - 1}`"
                    required
                  />
                  <span class="input-unit" v-html="question.unit"></span>
                </p> -->
            </td>
            <td style="text-align:center">
              <input
                class="checkbox"
                type="checkbox"
                :id="`${index}-${qindex}-prev-inv`"
                :value="`${index}-${qindex}-prev-inv`"
                :name="`${index}-${qindex}-prev-inv`"
              />
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="index === 7">
          <tr v-for="(question, qindex) in bilag.question" :key="qindex" :id="`tr-${index}-${qindex}`">
            <td>
              <div class="d-flex justify-space-between align-center">
                <div>
                  {{ question.text }}
                  <v-tooltip v-if="question.description !== null" bottom>
                    <template #activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
                    </template>
                    <div v-for="desc in question.description" :key="desc">
                      <span v-html="desc"></span>
                    </div>
                  </v-tooltip>
                </div>
                <div>
                  <v-btn v-if="qindex === 9" min-height="30" max-height="30" @click="DialogGFR">Calculator</v-btn>
                </div>
              </div>
            </td>
            <td v-for="answer in getType2(question.type)" :key="answer" :colspan="4 / getType2(question.type)">
              <div v-if="question.type === 1" class="d-flex flex-row justify-center align-center">
                <input
                  class="yesnoInput"
                  type="radio"
                  :value="(answer - 1) % 2"
                  :id="(answer < 3 ? `${index}-${qindex}-0` : `${index}-${qindex}-1`) + `-${(answer - 1) % 2}`"
                  :name="answer < 3 ? `${index}-${qindex}-0` : `${index}-${qindex}-1`"
                  :checked="answer === 1 || answer === 3"
                />
                <label :for="(answer < 3 ? `${index}-${qindex}-0` : `${index}-${qindex}-1`) + `-${(answer - 1) % 2}`">{{
                  answer % 2 ? 'No' : 'Yes'
                }}</label>
              </div>
              <v-text-field
                :id="answer < 2 ? `${index}-${qindex}-0-0` : `${index}-${qindex}-1-0`"
                style="margin-right: 24px"
                v-else-if="question.type === 2"
                type="number"
                value="-1"
                dense
                hide-details
                outlined
                :ref="answer < 2 ? `${index}-${qindex}-0-0` : `${index}-${qindex}-1-0`"
                :suffix="question.unit"
                :rules="[rules.positive]"
              ></v-text-field>
              <!-- <p v-else-if="question.type === 2" class="pDiv d-flex flex-row">
                  <input
                    class="input-field"
                    type="number"
                    value="0"
                    :id="answer < 2 ? `${index}-${qindex}-0-0` : `${index}-${qindex}-1-0`"
                    :name="answer < 2 ? `${index}-${qindex}-0` : `${index}-${qindex}-1`"
                    required
                  />
                  <span class="input-unit" v-html="question.unit"></span>
                </p> -->
            </td>
            <td style="text-align:center">
              <input
                class="checkbox"
                type="checkbox"
                :id="`${index}-${qindex}-prev-inv`"
                :value="`${index}-${qindex}-prev-inv`"
                :name="`${index}-${qindex}-prev-inv`"
              />
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr v-for="(question, qindex) in bilag.question" :key="qindex">
            <td>
              {{ question.text }}
              <v-tooltip v-if="question.description !== null" bottom>
                <template #activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
                </template>
                <div v-for="desc in question.description" :key="desc">
                  {{ desc }}
                </div>
                <img
                  v-if="index === 1 && qindex === 0"
                  src="../assets/rule_of_nines.png"
                  width="400px"
                  height="400px"
                />
              </v-tooltip>
            </td>
            <td v-for="answer in getType(question.type)" :key="answer" :colspan="6 / getType(question.type)">
              <div v-if="question.type === 0">
                <div class="unit-td" style="border-bottom: 1px solid black; margin-bottom: 5px; text-align: center;">
                  {{ units[answer - 1] }}
                </div>
                <div>
                  <input
                    class="radioInput"
                    type="radio"
                    :value="answer === 6 ? -1 : answer - 1"
                    :id="`${index}-${qindex}-${answer - 1}`"
                    :name="`${index}-${qindex}`"
                    :checked="answer === 1"
                  />
                </div>
              </div>
              <div v-else-if="question.type === 1" class="d-flex flex-row justify-center align-center">
                <input
                  class="yesnoInput"
                  type="radio"
                  :value="answer - 1"
                  :id="`${index}-${qindex}-${answer - 1}`"
                  :name="`${index}-${qindex}`"
                  :checked="answer === 1"
                />
                <label :for="`${index}-${qindex}-${answer - 1}`">{{ answer % 2 ? 'No' : 'Yes' }}</label>
              </div>
              <v-text-field
                :id="`${index}-${qindex}-${answer - 1}-0`"
                style="margin-right: 24px"
                v-else-if="question.type === 2"
                type="number"
                value="-1"
                dense
                hide-details
                outlined
                :ref="`${index}-${qindex}-${answer - 1}-0`"
                :suffix="question.unit"
                :rules="[rules.positive]"
              ></v-text-field>
            </td>
            <td style="text-align:center">
              <input
                class="checkbox"
                type="checkbox"
                :id="`${index}-${qindex}-prev-inv`"
                :value="`${index}-${qindex}-prev-inv`"
                :name="`${index}-${qindex}-prev-inv`"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </details>
    <my-dialog
      v-if="index === 7"
      :show-dialog="dialog_Gfr"
      :serum_creatinine="getSerumCreatinine"
      @closeDialog="DialogGFR"
      @setGFR="setGFR"
    />
  </div>
</template>

<script>
import Dialog from './CalculateGFR.vue';

export default {
  name: 'BilagForm',
  components: {
    'my-dialog': Dialog,
  },
  props: {
    bilag: {
      required: true,
      type: Object,
    },
    index: {
      required: true,
      type: Number,
    },
  },
  computed: {
    getType() {
      return (type) => {
        if (type === 0) return 6;
        else if (type === 1) return 2;
        else if (type === 2) {
          if (this.index === 7) return 2;
          else return 1;
        }
      };
    },
    getType2() {
      return (type) => {
        if (type === 1) return 4;
        else return 2;
      };
    },
  },
  watch: {},
  data: () => ({
    units: ['Not Present', 'Improving', 'Same', 'Worse', 'New', 'Not Done'],
    rules: {
      required: (value) => !!value || 'required',
      positive: (value) => value >= -1 || '>= -1',
    },
    dialog_Gfr: false,
    getSerumCreatinine: -1,
  }),
  methods: {
    SetBilagData() {
      let value = [];
      let prevInv = [];
      for (let i = 0; i < this.bilag.question.length; i++) {
        if (this.bilag.question[i].type === 2) {
          if (this.index !== 7) {
            let e = document.getElementById(`${this.index}-${i}-0-0`);
            if (e.value === '' || Number(e.value) < -1) {
              e.focus();
              return;
            } else {
              value.push(Number(e.value));
            }
          } else {
            let v_p = [];
            let e = document.getElementById(`${this.index}-${i}-0-0`);
            let e_p = document.getElementById(`${this.index}-${i}-1-0`);
            if (e.value === '' || Number(e.value) < -1) {
              e.focus();
              return;
            } else if (e_p.value === '' || Number(e_p.value) < -1) {
              e.focus();
              return;
            } else {
              v_p.push(Number(e.value));
              v_p.push(Number(e_p.value));
            }
            value.push(v_p);
          }
        } else {
          if (this.index !== 7) {
            value.push(Number(document.querySelector(`input[name="${this.index}-${i}"]:checked`).value));
          } else {
            value.push([
              Number(document.querySelector(`input[name="${this.index}-${i}-0"]:checked`).value),
              Number(document.querySelector(`input[name="${this.index}-${i}-1"]:checked`).value),
            ]);
          }
        }
      const prevInvValue = document.getElementById(`${this.index}-${i}-prev-inv`);
      prevInv.push(prevInvValue.checked);
      }
      let payload = {
        index: this.index,
        value: value,
        prevInv: prevInv,
      };
      this.$store.dispatch('SetBilagData', payload);
      this.$emit('finishSetBilagData', this.index);
    },
    // TODO prev invov
    RefreshData() {
      const clonedeep = require('lodash.clonedeep');
      let bilagData = clonedeep(this.$store.state.bilagData);
      let prevInvData = clonedeep(this.$store.state.prevInvData);
      for (let i = 0; i < this.bilag.question.length; i++) {
        if (this.bilag.question[i].type !== 2) {
          if (bilagData[this.index][i].length && bilagData[this.index][i].length > 1) {
            for (let j = 0; j < bilagData[this.index][i].length; j++) {
              i;
              document.getElementById(`${this.index}-${i}-${j}-${bilagData[this.index][i][j]}`).checked = true;
            }
          } else {
            if (bilagData[this.index][i] === -1) document.getElementById(`${this.index}-${i}-5`).checked = true;
            else {
              document.getElementById(`${this.index}-${i}-${bilagData[this.index][i]}`).checked = true;
            }
          }
        } else {
          if (bilagData[this.index][i].length && bilagData[this.index][i].length > 1) {
            for (let j = 0; j < bilagData[this.index][i].length; j++) {
              this.$refs[`${this.index}-${i}-${j}-0`][0].value = bilagData[this.index][i][j];
              // document.getElementById(`${this.index}-${i}-${j}-0`).value = bilagData[this.index][i][j];
            }
          } else {
            this.$refs[`${this.index}-${i}-0-0`][0].value = bilagData[this.index][i];
            // document.getElementById(`${this.index}-${i}-0-0`).value = bilagData[this.index][i];
          }
        }
        let prevInv = document.getElementById(`${this.index}-${i}-prev-inv`);
        if (prevInvData.length > 0 && prevInvData[this.index] && prevInvData[this.index][i] === true) prevInv.checked = true;
        else prevInv.checked = false;
      }
      this.$store.dispatch('SetResultData',[]);
      // this.$store.dispatch('ResetBilagData');
    },
    DialogGFR() {
      this.getSerumCreatinine = Number(document.getElementById(`${this.index}-8-0-0`).value);
      this.dialog_Gfr = !this.dialog_Gfr;
    },
    setGFR(value) {
      document.getElementById(`${this.index}-9-0-0`).value = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.bilagform-wrapper {
  width: 880px;
  .details {
    cursor: pointer;
  }
  table {
    margin: 30px 0;
    border-collapse: collapse;
    border-spacing: 0;
    thead {
      text-align: center;
    }
    td,
    th {
      position: relative;
      padding: 4px;
      border: 1px solid black;
    }
    .question-td {
      width: 340px;
    }
    .unit-td {
      width: 90px;
    }
  }
  .radioInput {
    vertical-align: middle;
    width: 100%;
    height: 30px;
  }
  .yesnoInput {
    vertical-align: middle;
    width: 35px;
    height: 30px;
  }
  .checkbox {
    vertical-align: middle;
    width: 25px;
    height: 25px;
  }

  .pDiv {
    width: 100%;
    height: 30px;
    margin: 0;
    position: relative;
    .input-field {
      width: calc(100% - 120px);
      background: rgba(0, 0, 0, 0.1);
      height: 100%;
    }
    .input-field:focus {
      background: #edfffb;
      outline: none;
    }
    .input-unit {
      width: 120px;
      background: rgba(0, 0, 0, 0.1);
      border: black solid 0;
      border-left-width: 1px;
    }
  }
  .tbIntb {
    width: 100%;
    margin: 0;
    border: 1px;
    td {
      border: 0;
    }
  }
}
</style>
<style lang="scss">
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
