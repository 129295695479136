export function CalculateBilag(vue) {
  let bilagData = vue.$store.state.bilagData;
  let prevInvData = vue.$store.state.prevInvData;
  let resultBilagData = [];

  // Constitutional, 1
  let cons_v = bilagData[0];
  let cons_prev = prevInvData[0].includes(true);
  let constitutional = '';
  let sum_wla = (cons_v[1] > 1 ? 1 : 0) + (cons_v[2] > 1 ? 1 : 0) + (cons_v[3] > 1 ? 1 : 0);
  if (cons_v[0] >= 2 && sum_wla >= 2) constitutional = 'A';
  else if (cons_v[0] >= 2 || sum_wla >= 2) constitutional = 'B';
  else if (
    CompareValue(cons_v, [
      [0, '=', 1],
      [1, '>', 0],
      [2, '>', 0],
      [3, '>', 0],
    ])
  )
    constitutional = 'C';
  else if (cons_prev === true) constitutional = 'D';
  else constitutional = 'E';
  resultBilagData.push(constitutional);

  // Mucocutaneous, 2
  let muc_v = bilagData[1];
  let muc_prev = prevInvData[1].includes(true);
  let mucocutaneous = '';
  if (
    CompareValue(muc_v, [
      [0, '>=', 2],
      [2, '>=', 2],
      [4, '>=', 2],
      [6, '>=', 2],
      [8, '>=', 2],
    ])
  )
    mucocutaneous = 'A';
  else if (
    CompareValue(muc_v, [
      [0, '=', 1],
      [2, '=', 1],
      [4, '=', 1],
      [6, '=', 1],
      [8, '=', 1],
      [1, '>=', 2],
      [7, '>=', 2],
      [9, '>=', 2],
      [10, '>=', 2],
    ])
  )
    mucocutaneous = 'B';
  else if (
    CompareValue(muc_v, [
      [1, '=', 1],
      [7, '=', 1],
      [9, '=', 1],
      [10, '=', 1],
      [3, '>', 0],
      [5, '>', 0],
      [11, '>', 0],
      [12, '>', 0],
      [13, '>', 0],
    ])
  )
    mucocutaneous = 'C';
  else if (muc_prev === true) mucocutaneous = 'D';
  else mucocutaneous = 'E';
  resultBilagData.push(mucocutaneous);

  // Neuropsychiatric, 3
  let neu_v = bilagData[2];
  let neu_prev = prevInvData[2].includes(true);
  let neuropsychiatric = '';
  if (
    CompareValue(neu_v, [
      [0, '>=', 2],
      [1, '>=', 2],
      [2, '>=', 2],
      [3, '>=', 2],
      [4, '>=', 2],
      [5, '>=', 2],
      [6, '>=', 2],
      [7, '>=', 2],
      [8, '>=', 2],
      [9, '>=', 2],
      [10, '>=', 2],
      [12, '>=', 2],
      [17, '>=', 2],
    ])
  )
    neuropsychiatric = 'A';
  else if (
    CompareValue(neu_v, [
      [0, '=', 1],
      [1, '=', 1],
      [2, '=', 1],
      [3, '=', 1],
      [4, '=', 1],
      [5, '=', 1],
      [6, '=', 1],
      [7, '=', 1],
      [8, '=', 1],
      [9, '=', 1],
      [10, '=', 1],
      [12, '=', 1],
      [17, '=', 1],
      [11, '>=', 2],
      [13, '>=', 2],
      [14, '>=', 2],
      [15, '>=', 2],
      [16, '>=', 2],
      [18, '>=', 2],
      [19, '>=', 2],
    ])
  )
    neuropsychiatric = 'B';
  else if (
    CompareValue(neu_v, [
      [11, '=', 1],
      [13, '=', 1],
      [14, '=', 1],
      [15, '=', 1],
      [16, '=', 1],
      [18, '=', 1],
      [19, '=', 1],
    ])
  )
    neuropsychiatric = 'C';
  else if (neu_prev === true) neuropsychiatric = 'D';
  else neuropsychiatric = 'E';
  resultBilagData.push(neuropsychiatric);

  // Musculoskeletal, 4
  let mus_v = bilagData[3];
  let mus_prev = prevInvData[3].includes(true);
  let musculoskeletal = '';
  if (
    CompareValue(mus_v, [
      [0, '>=', 2],
      [2, '>=', 2],
    ])
  )
    musculoskeletal = 'A';
  else if (
    CompareValue(mus_v, [
      [0, '=', 1],
      [2, '=', 1],
      [1, '>=', 2],
      [3, '>=', 2],
    ])
  )
    musculoskeletal = 'B';
  else if (
    CompareValue(mus_v, [
      [1, '=', 1],
      [3, '=', 1],
      [4, '>', 0],
    ])
  )
    musculoskeletal = 'C';
  else if (mus_prev === true) musculoskeletal = 'D';
  else musculoskeletal = 'E';
  resultBilagData.push(musculoskeletal);

  // Cariosrespiratory, 5
  let car_v = bilagData[4];
  let car_prev = prevInvData[4].includes(true);
  let cariosrespiratory = '';
  if (
    CompareValue(car_v, [
      [1, '>=', 2],
      [2, '>=', 2],
      [3, '>=', 2],
      [5, '>=', 2],
      [6, '>=', 2],
      [7, '>=', 2],
      [8, '>=', 2],
      [9, '>=', 2],
      [10, '>=', 2],
      [11, '>=', 2],
    ])
  )
    cariosrespiratory = 'A';
  else if (
    CompareValue(car_v, [
      [1, '=', 1],
      [2, '=', 1],
      [3, '=', 1],
      [5, '=', 1],
      [6, '=', 1],
      [7, '=', 1],
      [8, '=', 1],
      [9, '=', 1],
      [10, '=', 1],
      [11, '=', 1],
      [0, '>=', 2],
      [4, '>=', 2],
    ])
  )
    cariosrespiratory = 'B';
  else if (
    CompareValue(car_v, [
      [0, '=', 1],
      [4, '=', 1],
    ])
  )
    cariosrespiratory = 'C';
  else if (car_prev === true) cariosrespiratory = 'D';
  else cariosrespiratory = 'E';
  resultBilagData.push(cariosrespiratory);

  // Gastrointestinal, 6
  let gas_v = bilagData[5];
  let gas_prev = prevInvData[5].includes(true);
  let gastrointestinal = '';
  if (
    CompareValue(gas_v, [
      [0, '>=', 2],
      [2, '>=', 2],
      [5, '>=', 2],
      [7, '>=', 2],
      [8, '>=', 2],
    ])
  )
    gastrointestinal = 'A';
  else if (
    CompareValue(gas_v, [
      [0, '=', 1],
      [2, '=', 1],
      [5, '=', 1],
      [7, '=', 1],
      [8, '=', 1],
      [1, '>=', 2],
      [3, '>=', 2],
      [4, '>=', 2],
      [6, '>=', 2],
    ])
  )
    gastrointestinal = 'B';
  else if (
    CompareValue(gas_v, [
      [1, '=', 1],
      [3, '=', 1],
      [4, '=', 1],
      [6, '=', 1],
    ])
  )
    gastrointestinal = 'C';
  else if (gas_prev === true) gastrointestinal = 'D';
  else gastrointestinal = 'E';
  resultBilagData.push(gastrointestinal);

  // Ophthamic, 7
  let oph_v = bilagData[6];
  let oph_prev = prevInvData[6].includes(true);
  let ophthamic = '';
  if (
    CompareValue(oph_v, [
      [0, '>=', 2],
      [1, '>=', 2],
      [4, '>=', 2],
      [7, '>=', 2],
      [9, '>=', 2],
      [11, '>=', 2],
      [12, '>=', 2],
    ])
  )
    ophthamic = 'A';
  else if (
    CompareValue(oph_v, [
      [0, '=', 1],
      [1, '=', 1],
      [4, '=', 1],
      [7, '=', 1],
      [9, '=', 1],
      [11, '=', 1],
      [12, '=', 1],
      [2, '>=', 2],
      [3, '>=', 2],
      [5, '>=', 2],
      [8, '>=', 2],
    ])
  )
    ophthamic = 'B';
  else if (
    CompareValue(oph_v, [
      [2, '=', 1],
      [3, '=', 1],
      [5, '=', 1],
      [8, '=', 1],
      [6, '>', 0],
      [10, '>', 0],
    ])
  )
    ophthamic = 'C';
  else if (oph_prev === true) ophthamic = 'D';
  else ophthamic = 'E';
  resultBilagData.push(ophthamic);

  // Renal, 8
  let ren_v = bilagData[7];
  let ren_prev = prevInvData[7].includes(true);
  let renal = '';
  let SBP_c = ren_v[0][0];
  let DBP_c = ren_v[1][0];
  let accBP_c = ren_v[2][0];
  let udipstick_c = ren_v[3][0];
  let uACR_c = 113.1 * ren_v[4][0]; //mg/mg to mg/mmol
  let uPCR_c = 113.1 * ren_v[5][0]; //mg/mg to mg/mmol
  let u24hour_c = ren_v[6][0];
  let NS_c = ren_v[7][0];
  let sCr_c = 88.42 * ren_v[8][0]; //mg/dL to umol/L
  let GFR_c = ren_v[9][0];
  let uSed_c = ren_v[10][0];
  let Hist_c = ren_v[11][0];

  let SBP_p = ren_v[0][1];
  let DBP_p = ren_v[1][1];
  // let accBP_p = ren_v[2][1];
  let udipstick_p = ren_v[3][1];
  let uACR_p = 113.1 * ren_v[4][1]; //mg/mg to mg/mmol
  let uPCR_p = 113.1 * ren_v[5][1]; 
  let u24hour_p = ren_v[6][1];
  // let NS_p = ren_v[7][1];
  let sCr_p = 88.42 * ren_v[8][1]; //mg/dL to umol/L
  let GFR_p = ren_v[9][1];
  // let uSed_p = ren_v[10][1];
  // let Hist_p = ren_v[11][1];

  let A1 =
    (udipstick_p>=0 && udipstick_c>=0)&&(udipstick_c - udipstick_p >= 2) ||
    (u24hour_p>=0 && u24hour_c>=0)&&(u24hour_c > 1 && u24hour_c > 0.75 * u24hour_p) ||
    (uPCR_p>=0 && uPCR_c>=0)&&(uPCR_c > 100 && uPCR_c > 0.75 * uPCR_p) ||
    (uACR_p>=0 && uACR_c>=0)&&(uACR_c > 100 && uACR_c > 0.75 * uACR_p);
  let A2 = accBP_c === 1;
  let A3 =
  (sCr_p>=0 && sCr_c>=0)&&(sCr_c > 130 && sCr_c > 1.3 * sCr_p) ||
  (GFR_p>=0 && GFR_c>=0)&&(GFR_c < 80 && GFR_c < 0.67 * GFR_p) ||
  (GFR_p>=0)&&(GFR_c < 50 && (GFR_p > 50 || GFR_p === -1));
  let A4 = uSed_c === 1;
  let A5 = Hist_c === 1;
  let A6 = NS_c === 1;

  let B1 = A1 || A2 || A3 || A4 || A5 || A6;
  let B2 =
    (udipstick_p>=0 && udipstick_c>=0)&&(udipstick_c >= 2 && udipstick_c - udipstick_p === 1) ||
    (u24hour_p>=0 && u24hour_c>=0)&&(u24hour_c >= 0.5 && u24hour_c > 0.75 * u24hour_p) ||
    (uPCR_p>=0 && uPCR_c>=0)&&(uPCR_c >= 50 && uPCR_c > 0.75 * uPCR_p) ||
    (uACR_p>=0 && uACR_c>=0)&&(uACR_c >= 50 && uACR_c > 0.75 * uACR_p);
  let B3 = (sCr_p>=0 && sCr_c>=0)&& (sCr_c >= 130 && sCr_c > 1.15 * sCr_p && sCr_c < 1.3 * sCr_p);

  let C1 = (udipstick_c>=0&&u24hour_c>=0&&uPCR_c>=0&&uACR_c>=0) && (udipstick_c >= 1 || u24hour_c > 0.25 || uPCR_c > 25 || uACR_c > 25);
  let C2 = (SBP_c>=0&&SBP_p>=0&&DBP_c>=0&&DBP_p>=0) && (SBP_c > 140 && SBP_c - SBP_p >= 30 && DBP_c > 90 && DBP_c - DBP_p >= 15);
  if ((A1 || A4 || A5) && (A1 + A2 + A3 + A4 + A5 + A6 >= 2)) renal = 'A';
  else if (B1 || B2 || B3) renal = 'B';
  else if (C1 || C2) renal = 'C';
  else if (ren_prev === true) renal = 'D';
  else renal = 'E';

  resultBilagData.push(renal);

  // Hematology, 9
  let hem_v = bilagData[8];
  let hem_prev = prevInvData[8].includes(true);
  let hematology = '';
  let Hb_c = hem_v[0];
  let WBC_c = hem_v[1] / 1000; // /uL to 10^9/L
  let Neut_c = hem_v[2] / 1000; // /uL to 10^9/L
  let Lymph_c = hem_v[3] / 1000;// /uL to 10^9/L
  let Plt_c = hem_v[4]; // 10^3/uL to 10^9/L
  let TTP_c = hem_v[5];
  let Hemolysis_c = hem_v[6];
  let Coombs_c = hem_v[7];

  if (TTP_c > 1 || (Hb_c>=0)&&(Hemolysis_c === 1 && Hb_c < 8) || Plt_c>=0 && Plt_c < 25) hematology = 'A';

  else if (
    TTP_c === 1 ||
    (Hemolysis_c>=0&&Hb_c>=0)&&(Hemolysis_c === 1 && Hb_c >= 8 && Hb_c < 9.9) ||
    Hb_c>=0&&(Hemolysis_c === 0 && Hb_c < 8) ||
    WBC_c>=0&&WBC_c < 1.0 ||
    Neut_c>=0&&Neut_c < 0.5 ||
    (Plt_c >= 25 && Plt_c <= 49)
  )
    hematology = 'B';
  else if (
    (Hemolysis_c === 1 && Hb_c > 10) ||
    (Hemolysis_c === 0 && Hb_c >= 8 && Hb_c <= 10.9) ||
    (WBC_c >= 1.0 && WBC_c <= 3.9) ||
    (Neut_c >= 0.5 && Neut_c <= 1.9) ||
    Lymph_c>=0&&Lymph_c < 1.0 ||
    (Plt_c >= 50 && Plt_c <= 149) ||
    Coombs_c === 1
  )
    hematology = 'C';
  else if (hem_prev === true) hematology = 'D';
  else hematology = 'E';
  resultBilagData.push(hematology);

  vue.$store.dispatch('SetResultData', resultBilagData);
}
function CompareValue(value, condition) {
  for (let i = 0; i < condition.length; i++) {
    if (condition[i][1] === '>=') {
      if ((Number.parseInt(value[condition[i][0]]) >= condition[i][2])) { 
        return true;
      }
    } else if (condition[i][1] === '>') {
      if ((Number.parseInt(value[condition[i][0]]) > condition[i][2])) return true;
    } else if (condition[i][1] === '=') {
      if ((Number.parseInt(value[condition[i][0]]) === condition[i][2])) return true;
    }
  }
  return false;
}

export default {
  CalculateBilag,
  CompareValue,
};
