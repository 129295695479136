import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    init: false,
    initData: [],
    bilagData: [],
    resultData: [],
    prevInvData: [],
    initPrevInvData: [],
  },
  mutations: {
    InitBilag(state) {
      if (!state.init) {
        const clonedeep = require("lodash.clonedeep")
        state.init = true;
        state.initData = clonedeep(state.bilagData);
        state.resultData = [];
      }
    },
    SetBilagData(state, { index, value, prevInv }) {
      state.bilagData[index] = value;
      state.prevInvData[index] = prevInv;
    },
    SetResultData(state, value) {
      state.resultData = value;
    },
    // TODO text field 안됨
    ResetBilagData(state) {
      const clonedeep = require("lodash.clonedeep")
      state.bilagData = clonedeep(state.initData);
      state.prevInvData = clonedeep(state.initPrevInvData);
    },
    ReadBilagDataOnFile(state, rows) {
      let categoryIdx = 0;
      let j = 0;
      for (let i = 0; i < rows.length; i++) {
        if (Object.prototype.hasOwnProperty.call(rows[i], 'Record')) {
          if (i < 3) continue;
          else if (i === 21 || i === 36 || i === 57 || i === 63 || i === 76 || i === 86 || i === 100 || i === 113) {
            categoryIdx++;
            j = 0;
          }
          if ((i >= 100 && i < 112)) {
            let tmp = [];
            tmp.push(rows[i]['Record']);
            tmp.push(rows[i]['Record(4 Weeks Ago)']);
            state.bilagData[categoryIdx][j] = tmp;
            j++;
          } else {
            state.bilagData[categoryIdx][j] = rows[i]['Record'];
            j++;
          }
        }
        if (Object.prototype.hasOwnProperty.call(rows[i], 'Previously Involved')) {
          state.prevInvData[categoryIdx][j-1] = rows[i]['Previously Involved'];
        }
      }
    },
  },
  actions: {
    SetBilagData(context, value) {
      context.commit('SetBilagData', value);
    },
    SetResultData(context, value) {
      context.commit('SetResultData', value);
    },
    ReadBilagDataOnFile(context, rows) {
      context.commit('ReadBilagDataOnFile', rows);
    },
    ResetBilagData(context) {
      context.commit('ResetBilagData');
    },
    InitBilag(context) {
      context.commit('InitBilag');
    }
  },
});
