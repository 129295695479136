<template>
  <layout>
    <div class="file-control-wrapper d-flex align-center">
      <v-file-input
        class="pr-5"
        label="File input"
        accept=".csv, .txt, .html, .json, .xlsx"
        @change="SelectFile"
      ></v-file-input>
      <v-btn @click="FileSubmit">Submit</v-btn>
    </div>
    <div class="inform-wrapper d-flex flex-row justify-space-between">
      <v-text-field v-model="number" label="Patient Number"></v-text-field>
      <v-text-field v-model="name" label="Patient Name"></v-text-field>
      <v-menu
        v-model="datePicker"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-model="date" label="Date" readonly v-bind="attrs" v-on="on"></v-text-field>
        </template>
        <v-date-picker v-model="date" @input="datePicker = false"></v-date-picker>
      </v-menu>
      <v-btn @click="ClickReset">Data Reset</v-btn>
    </div>
    <div class="form-wrapper" v-for="(bilag, index) in bilag_const" :key="`f-${index}`">
      <bilag-form :ref="`bilag_form`" :bilag="bilag" :index="index" @finishSetBilagData="FinishSetBilagData" />
    </div>
    <v-btn @click="ClickResult">Result</v-btn>
    <div
      class="result-wrapper d-flex flex-column"
      v-for="(result, resultIndex) in resultData"
      :key="`r-${resultIndex}`"
    >
      <div>{{ bilag_const[resultIndex].category }}: {{ result }}</div>
    </div>
    <v-btn v-if="resultData.length !== 0" @click="ClickSave">Save to file</v-btn>
  </layout>
</template>

<script>
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { Bilag } from '~/assets/bilag_const.js';
import { CalculateBilag } from '~/assets/calculate_bilag.js';
import { WriteXLSX } from '~/assets/write_xlsx.js';
import Layout from '~/layout/Default.vue';
import BilagForm from '~/components/BilagForm.vue';

export default {
  name: 'App',
  components: {
    layout: Layout,
    'bilag-form': BilagForm,
  },
  computed: {
    resultData() {
      return this.$store.state.resultData;
    },
  },
  data: () => ({
    bilag_const: Bilag,
    file: '',
    number: '',
    name: '',
    datePicker: false,
    date: new Date().toISOString().substr(0, 10),
  }),
  mounted() {
    this.ClickResult();
    this.InitBilag();
  },
  methods: {
    SelectFile(file) {
      this.file = file;
    },
    FileSubmit() {
      if (this.file === '') return;
      let input = this.file;
      let reader = new FileReader();
      reader.onload = () => {
        let data = reader.result;
        let workBook = XLSX.read(data, { type: 'binary', cellDates: true });
        workBook.SheetNames.forEach((sheetName) => {
          let rows = XLSX.utils.sheet_to_json(workBook.Sheets[sheetName]);
          this.number = rows[0].Record;
          this.name = rows[1].Record;
          this.date = new Date(rows[2].Record).toISOString().substr(0, 10);
          this.$store.dispatch('ReadBilagDataOnFile', rows);
          for (let i = 0; i < 9; i++) {
            this.$refs.bilag_form[i].RefreshData();
          }
        });
      };
      reader.readAsBinaryString(input);
    },
    InitBilag() {
      this.$store.dispatch('InitBilag');
    },
    ClickReset() {
      this.$store.dispatch('ResetBilagData');
      for (let i = 0; i < 9; i++) {
        this.$refs.bilag_form[i].RefreshData();
      }
    },
    ClickResult() {
      for (let i = 0; i < 9; i++) {
        this.$refs.bilag_form[i].SetBilagData();
      }
    },
    ClickSave() {
      let wb = XLSX.utils.book_new();
      wb.SheetNames.push('BILAG-2004 index');
      let wsData = WriteXLSX(this);
      let ws = XLSX.utils.aoa_to_sheet(wsData);
      wb.Sheets['BILAG-2004 index'] = ws;
      var wbout = XLSX.write(wb, { booType: 'xlsx', type: 'binary' });
      saveAs(
        new Blob([this.s2ab(wbout)], { type: 'application/octet-stream' }),
        `BILAG2004_${this.number}_${this.name}_${this.date}.xlsx`,
      );
    },
    FinishSetBilagData(index) {
      if (index === 8) {
        this.CalculateBilag();
      }
    },
    CalculateBilag() {
      CalculateBilag(this);
    },
    s2ab(s) {
      let buf = new ArrayBuffer(s.length);
      let view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    },
  },
};
</script>

<style lang="scss">
.file-control-wrapper {
  width: 640px;
}
.inform-wrapper {
  div {
    padding: 0 4px;
  }
}
.form-wrapper {
  margin: 0 auto;
}
.result-wrapper {
  padding-top: 20px;
}
</style>
