import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/Home.vue'
Vue.use(Router);

export const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      components: {
        default: Home,
      },
    },
  ],
});

export default { router };
