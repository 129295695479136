<template>
  <v-dialog :persistent="true" v-model="showDialog" width="350px">
    <v-layout class="dialog-wrapper" column>
      <div class="dialog-header d-flex flex-row justify-space-between">
        <div>Calculate GFR</div>
        <v-btn small icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="dialog-content d-flex flex-column justify-center align-center">
        <v-form ref="valid">
          <div class="pt-4">
            <v-text-field
              v-model="age"
              type="number"
              label="Age"
              dense
              :rules="[rules.required, rules.positive]"
            ></v-text-field>
            <v-radio-group v-model="sex" row>
              <v-radio label="Male" :value="false"></v-radio>
              <v-radio label="Female" :value="true"></v-radio>
            </v-radio-group>
            <span>African ancestry</span>
            <v-radio-group v-model="african_ancestry" row>
              <v-radio label="No" :value="false"></v-radio>
              <v-radio label="Yes" :value="true"></v-radio>
            </v-radio-group>
            <v-text-field
              :value="serum_creatinine"
              label="Serum creatinine"
              disabled
              suffix="mg/dL"
              :rules="[rules.positive]"
            ></v-text-field>
            <v-text-field
              v-model="serum_urea"
              type="number"
              label="Serum urea"
              suffix="mg/dL"
              dense
              :rules="[rules.required, rules.positive]"
              class="pt-2"
            ></v-text-field>
            <v-text-field
              v-model="serum_albumin"
              type="number"
              label="Serum albumin"
              suffix="g/dL"
              dense
              :rules="[rules.required, rules.positive]"
              class="pt-2"
            ></v-text-field>
            <div style="width: 100%; text-align: center; padding-top: 16px;">
              <v-btn @click="Calculate">Calculate</v-btn>
            </div>
          </div>
        </v-form>
      </div>
    </v-layout>
  </v-dialog>
</template>

<script>
export default {
  name: 'CalculateGFR',
  props: {
    showDialog: {
      required: true,
      type: Boolean,
    },
    serum_creatinine: {
      required: true,
      type: Number,
    },
  },
  data: () => ({
    rules: {
      required: (value) => !!value || 'required',
      positive: (value) => value > 0 || '> 0',
    },
    age: -1,
    sex: false,
    african_ancestry: false,
    serum_urea: -1,
    serum_albumin: -1,
  }),
  methods: {
    closeDialog() {
      this.$emit('closeDialog');
    },
    Calculate() {
      if (this.$refs.valid.validate() && this.serum_creatinine > 0) {
        let GFR =
          170 *
          this.serum_creatinine ** -0.999 *
          this.age ** -0.176 *
          this.serum_urea ** -0.17 *
          this.serum_albumin ** 0.318 *
          (this.sex ? 0.762 : 1) *
          (this.african_ancestry ? 1.18 : 1);
        this.$emit('setGFR', GFR.toFixed(2));
        this.closeDialog();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.dialog-wrapper {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  border-radius: 10px;
  background-color: #fafafa;
  font-family: NanumSquareOTFEB;
  & > * {
    padding: 12px;
  }
  .dialog-header {
    width: 100%;
    height: 50px;
    font-size: 18px;
    font-weight: bold;
    background-color: teal;
    color: white;
  }
  .dialog-content {
    height: 100%;
    width: 100%;
  }
  .dialog-footer {
    height: 100%;
    width: 100%;
  }
}
</style>
