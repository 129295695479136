import store from '../store/data';
import { Bilag } from './bilag_const.js';

// TODO previnvolv data
export function WriteXLSX(vue) {
  let bilagData = store.state.bilagData;
  let prevData = store.state.prevInvData;
  let wsData = [
    ['Question', 'Record', 'Record(4 Weeks Ago)', 'Previously Involved'],
    ['Hosp Number:', vue.number],
    ['Name:', vue.name],
    ['Date:', vue.date],
    [],
    ['* Only record manifestations/items due to SLE Disease Activity'],
    ['* Assessment refers to manifestations occuring in the last 4 weeks (compared with the previous 4 weeks)'],
    ['* To be used with the glossary'],
    [],
    ['*Record'],
    ['ND, not done'],
    ['0, not present'],
    ['1, improving'],
    ['2, same'],
    ['3, worse'],
    ['4, new'],
    ['(Yes – 1/No - 0) Or value (where indicated)'],
    ['* Y/n Confirm this is due to SLE activity (Yes – value/no – -1)'],
    [],
  ];
  for (let i = 0; i < Bilag.length; i++) {
    wsData.push([Bilag[i].category]);
    for (let j = 0; j < Bilag[i].question.length; j++) {
      if (bilagData[i][j].length > 1) {
        let tmp = [Bilag[i].question[j].text];
        for (let k = 0; k < bilagData[i][j].length; k++) {
          tmp.push(bilagData[i][j][k]);
        }
        tmp.push(prevData[i][j]);
        wsData.push(tmp);
      } else wsData.push([Bilag[i].question[j].text, bilagData[i][j], null, prevData[i][j]]);
    }
    wsData.push([]);
  }

  return wsData;
}
export default {
  WriteXLSX,
};
