export const Bilag = [
  {
    category: 'CONSTITUTIONAL',
    question: [
      { type: 0, text: '1. Pyrexia', description: ['temperature > 37.5˚C documented'] },
      { type: 0, text: '2. Unintentional weight loss > 5%', description: null },
      { type: 0, text: '3. Lymphadenopathy', description: ['lymph node more than 1 cm diameter', 'exclude infection'] },
      { type: 0, text: '4. Anorexia', description: null },
    ],
  },
  {
    category: 'MUCOCUTANEOUS',
    question: [
      {
        type: 0,
        text: '5. Severe eruption',
        description: [
          '> 18% body surface area',
          'any lupus rash except panniculitis, bullous lesion & angio-oedema',
          'body surface area (BSA) is estimated using the rules of nines (used to assess extent of burns) as follows:',
          'palm(excluding fingers) = 1% BSA',
          'each lower limb = 18% BSA',
          'each upper limb = 9% BSA',
          'torso (front) = 18% BSA',
          'torso (back) = 18% BSA',
          'head = 9% BSA',
          'genital (male) = 1% BSA',
        ],
      },
      {
        type: 0,
        text: '6. Mild eruption',
        description: [
          '≤ 18% body surface area',
          'any lupus rash except panniculitis, bullous lesion & angio-oedema',
          'malar rash must have been observed by a physician and has to be present continuously (persistent) for at least 1 week to be considered significant (to be recorded)',
        ],
      },
      {
        type: 0,
        text: '7. Severe angio-oedema',
        description: [
          'potentially life-threatening eg: stridor',
          'angio-oedema is a variant form of urticaria which affects the subcutaneous, submucosal and deep dermal tissues',
        ],
      },
      { type: 0, text: '8. Mild angio-oedema', description: ['not life threatening'] },
      {
        type: 0,
        text: '9. Severe mucosal ulceration ',
        description: [
          'disabling (significantly interfering with oral intake), extensive & deep ulceration',
          'must have been observed by a physician',
        ],
      },
      { type: 0, text: '10. Mild mucosal ulceration', description: ['localised &/or non-disabling ulceration'] },
      {
        type: 0,
        text: '11. Severe panniculitis or bullous lupus',
        description: [
          'any one:',
          '> 9% body surface area',
          'facial panniculitis',
          'panniculitis that is beginning to ulcerate',
          'panniculitis that threatens integrity of subcutaneous tissue (beginning to cause surface depression) on > 9% body surface area',
          'panniculitis presents as a palpable and tender subcutaneous induration/nodule',
          'note that established surface depression and atrophy alone is likely to be due to damage',
        ],
      },
      {
        type: 0,
        text: '12. Mild panniculitis or bullous lupus ',
        description: [
          '≤ 9% body surface area',
          'does not fulfil any criteria for severe panniculitis (for panniculitis)',
        ],
      },
      {
        type: 0,
        text: '13. Major cutaneous vasculitis/thrombosis',
        description: ['resulting in extensive gangrene or ulceration or skin infarction'],
      },
      {
        type: 0,
        text: '14. Digital infarct or nodular vasculitis',
        description: ['localised single or multiple infarct(s) over digit(s) or tender erythematous nodule(s)'],
      },
      {
        type: 0,
        text: '15. Severe alopecia',
        description: [
          'clinically detectable (diffuse or patchy) hair loss with scalp inflammation (redness over scalp)',
        ],
      },
      {
        type: 0,
        text: '16. Mild alopecia',
        description: ['diffuse or patchy hair loss without scalp inflammation (clinically detectable or by history)'],
      },
      {
        type: 0,
        text: '17. Peri-ungual erythema or chilblains',
        description: [
          'chilblains are localised inflammatory lesions (may ulcerate) which are precipitated by exposure to cold',
        ],
      },
      { type: 0, text: '18. Splinter haemorrhages', description: null },
    ],
  },
  {
    category: 'NEUROPSYCHIATRIC',
    question: [
      {
        type: 0,
        text: '19. Aseptic meningitis ',
        description: [
          'criteria (all): acute/subacute onset',
          'headache, fever, abnormal CSF (raised protein &/or',
          'lymphocyte predominance) but negative cultures',
          'preferably photophobia, neck stiffness and meningeal irritation should be present as well but are not essential for diagnosis',
          'exclude CNS/meningeal infection, intracranial haemorrhage',
        ],
      },
      {
        type: 0,
        text: '20. Cerebral vasculitis',
        description: [
          'should be present with features of vasculitis in another system',
          'supportive imaging &/or biopsy findings',
        ],
      },
      {
        type: 0,
        text: '21. Demyelinating syndrome',
        description: [
          'discrete white matter lesion with associated neurological deficit not recorded elsewhere',
          'ideally there should have been at least one previously recorded event',
          'supportive imaging required',
          'exclude multiple sclerosis',
        ],
      },
      {
        type: 0,
        text: '22. Myelopathy',
        description: [
          'acute onset of rapidly evolving paraparesis or quadriparesis and/or sensory level',
          'exclude intramedullary and extramedullary space occupying lesion',
        ],
      },
      {
        type: 0,
        text: '23. Acute confusional state',
        description: [
          'acute disturbance of consciousness or level of arousal with reduced ability to focus, maintain or shift attention',
          'includes hypo- and hyperaroused states and encompasses the spectrum from delirium to coma',
        ],
      },
      {
        type: 0,
        text: '24. Psychosis',
        description: [
          'delusion or hallucinations',
          'does not occur exclusively during course of a delirium',
          'exclude drugs, substance abuse, primary psychotic disorder',
        ],
      },
      {
        type: 0,
        text: '25. Acute inflammatory demyelinating polyradiculoneuropathy',
        description: [
          'delusion or hallucinations',
          'does not occur exclusively during course of a delirium',
          'exclude drugs, substance abuse, primary psychotic disorder',
        ],
      },
      {
        type: 0,
        text: '26. Mononeuropathy (single/multiplex)',
        description: ['supportive electrophysiology study required'],
      },
      {
        type: 0,
        text: '27. Cranial neuropathy',
        description: ['except optic neuropathy which is classified under ophthalmic system'],
      },
      {
        type: 0,
        text: '28. Plexopathy',
        description: [
          'disorder of brachial or lumbosacral plexus resulting in neurological deficit not corresponding to territory of single root or nerve',
          'supportive electrophysiology study required',
        ],
      },
      {
        type: 0,
        text: '29. Polyneuropathy',
        description: [
          'acute symmetrical distal sensory and/or motor deficit',
          'supportive electrophysiology study required',
        ],
      },
      {
        type: 0,
        text: '30. Seizure disorder',
        description: ['independent description of seizure by reliable witness'],
      },
      {
        type: 0,
        text: '31. Status epilepticus',
        description: ['a seizure or series of seizures lasting ≥ 30 minutes without full recovery to baseline'],
      },
      {
        type: 0,
        text: '32. Cerebrovascular disease (not due to vasculitis)',
        description: [
          'any one with supporting imaging:',
          'stroke syndrome',
          'transient ischaemic attack',
          'intracranial haemorrhage',
          'exclude hypoglycaemia, cerebral sinus thrombosis, vascular malformation, tumour, abscess',
          'cerebral sinus thrombosis not included as',
          'definite thrombosis not considered part of lupus activity',
        ],
      },
      {
        type: 0,
        text: '33. Cognitive dysfunction',
        description: [
          'significant deficits in any cognitive functions:',
          'simple attention (ability to register & maintain information)',
          'complex attention',
          'memory (ability to register, recall & recognise information eg learning, recall)',
          'visual-spatial processing (ability to analyse, synthesise & manipulate visual-spatial information)',
          'language (ability to comprehend, repeat & produce oral/written material eg verbal fluency, naming)',
          'reasoning/problem solving (ability to reason & abstract)',
          'psychomotor speed',
          'executive functions (eg planning, organising, sequencing)',
          'in absence of disturbance of consciousness or level of arousal',
          'sufficiently severe to interfere with daily activities',
          'neuropsychological testing should be done or corroborating history from third party if possible',
          'exclude substance abuse',
        ],
      },
      {
        type: 0,
        text: '34. Movement disorder',
        description: ['exclude drugs'],
      },
      {
        type: 0,
        text: '35. Autonomic disorder',
        description: [
          'any one:',
          'fall in blood pressure to standing > 30/15 mm Hg (systolic/diastolic)',
          'increase in heart rate to standing ≥ 30 bpm',
          'loss of heart rate variation with respiration (max – min < 15 bpm, expiration:inspiration ratio < 1.2, Valsalva ratio < 1.4)',
          'loss of sweating over body and limbs (anhidrosis) by sweat test',
          'exclude drugs and diabetes mellitus',
        ],
      },
      {
        type: 0,
        text: '36. Cerebellar ataxia',
        description: ['cerebellar ataxia in isolation of other CNSa features', 'usually subacute presentation'],
      },
      {
        type: 0,
        text: '37. Severe lupus headache (unremitting)',
        description: [
          'disabling headache unresponsive to narcotic analgesia & lasting ≥ 3 days',
          'exclude intracranial space occupying lesion and CNS infection',
        ],
      },
      {
        type: 0,
        text: '38. Headache from IC hypertension',
        description: ['exclude cerebral sinus thrombosis'],
      },
    ],
  },
  {
    category: 'MUSCULOSKELETAL',
    question: [
      {
        type: 0,
        text: '39. Severe myositis',
        description: [
          'significantly elevated serum muscle enzymes with significant muscle weakness',
          'exclude endocrine causes and drug-induced myopathy',
          'electromyography and muscle biopsy are used for diagnostic purpose and are not required to determine level of activity',
        ],
      },
      {
        type: 0,
        text: '40. Mild myositis',
        description: [
          'significantly elevated serum muscle enzymes with myalgia but without significant muscle weakness',
          'asymptomatic elevated serum muscle enzymes not included',
          'exclude endocrine causes and drug-induced myopathy',
          'electromyography and muscle biopsy are used for diagnostic purpose and are not required to determine level of activity',
        ],
      },
      {
        type: 0,
        text: '41. Severe arthritis',
        description: [
          'observed active synovitis ≥ 2 joints with marked loss of functional range of movements and significant impairment of activities of daily living, that has been present on several days (cumulatively) over the last 4 weeks',
        ],
      },
      {
        type: 0,
        text: '42. Moderate arthritis or Tendonitis or Tenosynovitis',
        description: [
          'tendonitis/tenosynovitis or active synovitis ≥ 1 joint (observed or through history) with some loss of functional range of movements, that has been present on several days over the last 4 weeks',
        ],
      },
      {
        type: 0,
        text: '43. Mild arthritis or Arthralgia or Myalgia',
        description: [
          'inflammatory type of pain (worse in the morning with stiffness, usually improves with activity & not brought on by activity) over joints/muscle Revision: 1/Sep/2009 9inflammatory arthritis which does not fulfil the above criteria for moderate or severe arthritis',
        ],
      },
    ],
  },
  {
    category: 'CARDIORESPIRATORY',
    question: [
      {
        type: 0,
        text: '44. Mild myocarditis',
        description: [
          'inflammation of myocardium with raised cardiac enzymes &/or ECG changes and without resulting cardiac failure, arrhythmia or valvular dysfunction',
        ],
      },
      {
        type: 0,
        text: '45. Cardiac failure',
        description: [
          'cardiac failure due to myocarditis or noninfective inflammation of endocardium or cardiac valves (endocarditis)',
          'cardiac failure due to myocarditis is defined by left ventricular ejection fraction ≤ 40% & pulmonary oedema or peripheral oedema',
          'cardiac failure due to acute valvular regurgitation (from endocarditis) can be associated with normal left ventricular ejection fraction',
          'diastolic heart failure is not included',
        ],
      },
      {
        type: 0,
        text: '46. Arrhythmia',
        description: [
          'arrhythmia (except sinus tachycardia) due to myocarditis or non-infective inflammation of endocardium or cardiac valves (endocarditis)',
          'confirmation by electrocardiogram required (history of palpitations alone inadequate)',
        ],
      },
      {
        type: 0,
        text: '47. New valvular dysfunction',
        description: [
          'new cardiac valvular dysfunction due to myocarditis or non-infective inflammation of endocardium or cardiac valves (endocarditis)',
          'supportive imaging required',
        ],
      },
      {
        type: 0,
        text: '48. Pleurisy/Pericarditis',
        description: [
          'convincing history &/or physical findings that you would consider treating',
          'in absence of cardiac tamponade or pleural effusion with dyspnoea',
          'do not score if you are unsure whether or not it is pleurisy/pericarditis',
        ],
      },
      {
        type: 0,
        text: '49. Cardiac tamponade',
        description: ['supportive imaging required'],
      },
      {
        type: 0,
        text: '50. Pleural effusion with dyspnoea',
        description: ['supportive imaging required'],
      },
      {
        type: 0,
        text: '51. Pulmonary haemorrhage/vasculitis',
        description: ['supportive imaging required', 'supportive imaging &/or histological diagnosis required'],
      },
      {
        type: 0,
        text: '52. Interstitial alveolitis/pneumonitis',
        description: [
          'radiological features of alveolar infiltration not due to infection or haemorrhage required for diagnosis',
          'corrected gas transfer Kco reduced to < 70% normal or fall of > 20% if previously abnormal',
          'on-going activity would be determined by clinical findings and lung function tests, and repeated imaging may be required in those with deterioration (clinically or lung function tests) or failure to respond to therapy',
        ],
      },
      {
        type: 0,
        text: '53. Shrinking lung syndromes',
        description: [
          'acute reduction (> 20% if previous measurement available) in lung volumes (to < 70% predicted) in the presence of normal corrected gas transfer (Kco) & dysfunctional diaphragmatic movements',
        ],
      },
      {
        type: 0,
        text: '54. Aortitis',
        description: [
          'inflammation of aorta (with or without dissection) with supportive imaging abnormalities',
          'accompanied by > 10 mm Hg difference in BP between arms &/or claudication of extremities &/or vascular bruits',
          'repeated imaging would be required to determine on-going activity in those with clinical  deterioration or failure to respond to therapy',
        ],
      },
      {
        type: 0,
        text: '55. Coronary vasculitis',
        description: [
          'inflammation of coronary vessels with radiographic evidence of non-atheromatous narrowing, obstruction or aneurysmal changes',
        ],
      },
    ],
  },
  {
    category: 'GASTROINTESTINAL',
    question: [
      {
        type: 0,
        text: '56. Lupus peritonitis',
        description: ['serositis presenting as acute abdomen with rebound/guarding'],
      },
      {
        type: 0,
        text: '57. Serositis',
        description: ['not presenting as acute abdomen'],
      },
      {
        type: 0,
        text: '58. Lupus enteritis or colitis',
        description: [
          'vasculitis or inflammation of small or large bowel with supportive imaging &/or biopsy findings',
        ],
      },
      {
        type: 0,
        text: '59. Malabsorption',
        description: [
          'diarrhoea with abnormal D- xylose absorption Revision: 1/Sep/2009 11 test or increased faecal fat excretion after exclusion of coeliac’s disease (poor response to gluten-free diet) and gut vasculitis',
        ],
      },
      {
        type: 0,
        text: '60. Protein-losing enteropathy',
        description: [
          'diarrhoea with hypoalbuminaemia or increased faecal excretion of iv radiolabeled albumin after exclusion of gut vasculitis and malabsorption',
        ],
      },
      {
        type: 0,
        text: '61. Intestinal pseudo-obstruction',
        description: ['subacute intestinal obstruction due to intestinal hypomotility'],
      },
      {
        type: 0,
        text: '62. Lupus hepatitis',
        description: [
          'raised transaminases',
          'absence of autoantibodies specific to autoimmune hepatitis (eg: anti-smooth muscle, anti-liver cytosol 1) &/or biopsy appearance of chronic active hepatitis',
          'hepatitis typically lobular with no piecemeal necrosis',
          'exclude drug-induced and viral hepatitis',
        ],
      },
      {
        type: 0,
        text: '63. Acute lupus cholecystitis',
        description: ['after exclusion of gallstones and infection'],
      },
      {
        type: 0,
        text: '64. Acute lupus pancreatitis',
        description: ['usually associated multisystem involvement'],
      },
    ],
  },
  {
    category: 'OPHTHALMIC',
    question: [
      {
        type: 0,
        text: '65. Orbital inflammation',
        description: [
          'orbital inflammation with myositis &/or extraocular muscle swelling &/or proptosis',
          'supportive imaging required',
        ],
      },
      {
        type: 0,
        text: '66. Severe keratitis',
        description: ['sight threatening', 'includes: corneal melt, peripheral ulcerative keratitis'],
      },
      {
        type: 0,
        text: '67. Mild keratitis',
        description: ['not sight threatening'],
      },
      {
        type: 0,
        text: '68. Anterior uveitis',
        description: null,
      },
      {
        type: 0,
        text: '69. Severe posterior uveitis &/or retinal vasculitis',
        description: ['sight-threatening &/or retinal vasculitis not due to vaso-occlusive disease'],
      },
      {
        type: 0,
        text: '70. Mild posterior uveitis &/or retinal vasculitis',
        description: ['not sight-threatening', 'not due to vaso-occlusive disease'],
      },
      {
        type: 0,
        text: '71. Episcleritis',
        description: null,
      },
      {
        type: 0,
        text: '72. Severe scleritis',
        description: [
          'necrotising anterior scleritis',
          'anterior &/or posterior scleritis requiring systemic steroids/immunosuppression &/or not responding to NSAIDs',
        ],
      },
      {
        type: 0,
        text: '73. Mild scleritis',
        description: [
          'anterior &/or posterior scleritis not requiring systemic steroids',
          'excludes necrotising anterior scleritis',
        ],
      },
      {
        type: 0,
        text: '74. Retinal/choroidal vaso-occlusive disease',
        description: [
          'includes: retinal arterial & venous occlusion serous retinal &/or retinal pigment epithelial detachments secondary to choroidal vasculopathy',
        ],
      },
      {
        type: 0,
        text: '75. Isolated cotton-wool spots',
        description: ['also known as cytoid bodies'],
      },
      {
        type: 0,
        text: '76. Optic neuritis',
        description: ['excludes anterior ischaemic optic neuropathy'],
      },
      {
        type: 0,
        text: '77. Anterior ischaemic optic neuropathy',
        description: ['visual loss with pale swollen optic disc due to occlusion of posterior ciliary arteries'],
      },
    ],
  },
  {
    category: 'RENAL',
    question: [
      {
        type: 2,
        text: '78. Systolic blood pressure',
        description: null,
        unit: 'mm Hg',
      },
      {
        type: 2,
        text: '79. Diastolic blood pressure',
        description: null,
        unit: 'mm Hg',
      },
      {
        type: 1,
        text: '80. Accelerated hypertension',
        description: [
          'blood pressure rising to > 170/110 mm Hg within 1 month with grade 3 or 4 KeithWagener-Barker retinal changes (flame-shaped haemorrhages or cotton-wool spots or papilloedema)',
        ],
      },
      {
        type: 2,
        text: '81. Urine dipstick',
        description: null,
      },
      {
        type: 2,
        text: '82. Urine albumin-creatinine ratio',
        description: [
          'on freshly voided urine sample',
          'conversion: 1 mg/mg = 113 mg/mmol',
          'it is important to exclude other causes (especially infection) when proteinuria is present',
        ],
        unit: 'mg/mg',
      },
      {
        type: 2,
        text: '83. Urine protein-creatinine ratio',
        description: [
          'on freshly voided urine sample',
          'conversion: 1 mg/mg = 113 mg/mmol',
          'it is important to exclude other causes (especially infection) when proteinuria is present',
        ],
        unit: 'mg/mg',
      },
      {
        type: 2,
        text: '84. 24 hour urine protein',
        description: ['it is important to exclude other causes (especially infection) when proteinuria is present'],
        unit: 'g',
      },
      {
        type: 1,
        text: '85. Nephrotic syndrome',
        description: [
          'criteria:',
          'heavy proteinuria ( ' +
            String.fromCharCode(8805) +
            ' 3.5 g/day or protein-creatinine ratio ' +
            String.fromCharCode(8805) +
            ' 350 mg/mmol or albumin-creatinine ratio ' +
            String.fromCharCode(8805) +
            ' 350 mg/mmol)',
          'hypoalbuminaemia, oedema',
        ],
      },
      {
        type: 2,
        text: '86. Plasma/Serum creatinine',
        description: ['exclude other causes for increase in creatinine (especially drugs)'],
        unit: 'mg/dL',
      },
      {
        type: 2,
        text: '87. GFR',
        description: [
          'MDRD formula:',
          'GFR = 170 x [serum creatinine (mg/dl)]<sup>-0.999</sup> x [age]<sup>-0.176</sup> x [serum urea (mg/dl]<sup>-0.17</sup> x [serum albumin (g/dl)]<sup>0.318</sup> x [0.762 if female] x [1.180 if African ancestry]',
          'units = ml/min per 1.73 m^2',
          'normal: male = 130 ± 40, female = 120 ± 40',
          'conversion:',
          'serum creatinine - mg/dl = (' + String.fromCharCode(181) + 'mol/l)/88.5',
          'serum urea - mg/dl = (mmol/l) x 2.8',
          'serum albumin - g/dl = (g/l)/1',
          'creatinine clearance not recommended as it is not reliable',
          'exclude other causes for decrease in GFR (especially drugs)',
        ],
        unit: 'ml/min/1.73m' + String.fromCharCode(178),
      },
      {
        type: 1,
        text: '88. Active urinary sediment',
        description: [
          'pyuria (> 5 WCC/hpf or > 10 WCC/mm3 (' + String.fromCharCode(181) + 'l)) OR',
          'haematuria (> 5 RBC/hpf or > 10 RBC/mm3 (' + String.fromCharCode(181) + 'l)) OR',
          'red cell casts OR',
          'white cell casts OR',
          'exclude other causes (especially infection, vaginal bleed, calculi)',
        ],
      },
      {
        type: 1,
        text: '89. Histology of active nephritis',
        description: [
          'WHO Classification (1995): (any one)',
          'Class III – (a) or (b) subtypes',
          'Class IV – (a), (b) or (c) subtypes',
          'Class V – (a), (b), (c) or (d) subtypes',
          'Vasculitis',
          'OR',
          'ISN/RPS Classification (2003): (any one)',
          'Class III – (A) or (A/C) subtypes',
          'Class IV – (A) or (A/C) subtypes',
          'Class V',
          'Vasculitis',
          'within last 3 months',
          'glomerular sclerosis without inflammation not included',
        ],
      },
    ],
  },
  {
    category: 'HAEMATOLOGICAL',
    question: [
      {
        type: 2,
        text: '90. Haemoglobin',
        description: ['exclude dietary deficiency & GI blood loss'],
        unit: 'g/dl',
      },
      {
        type: 2,
        text: '91. White cell count',
        description: ['exclude drug-induced cause'],
        unit: '/' + String.fromCharCode(181) + 'l',
      },
      {
        type: 2,
        text: '92. Neutrophil count',
        description: ['exclude drug-induced cause'],
        unit: '/' + String.fromCharCode(181) + 'l',
      },
      {
        type: 2,
        text: '93. Lymphocyte count',
        description: null,
        unit: '/' + String.fromCharCode(181) + 'l',
      },
      {
        type: 2,
        text: '94. Platelet count',
        description: ['exclude drug-induced cause'],
        unit: 'x 10' + String.fromCharCode(179) + '/' + String.fromCharCode(181) + 'l',
      },
      {
        type: 0,
        text: '95. TTP',
        description: [
          'thrombotic thrombocytopaenic purpura',
          'clinical syndrome of micro-angiopathic haemolytic anaemia and thrombocytopenia in absence of any other identifiable cause',
        ],
      },
      {
        type: 1,
        text: '96. Evidence of active haemolysis',
        description: [
          'positive Coombs’ test & evidence of haemolysis (raised bilirubin or raised reticulocyte count or reduced haptoglobulins or fragmented RBC or microspherocytes)',
        ],
      },
      {
        type: 1,
        text: '97. Isolated positive Coombs’ test',
        description: null,
      },
    ],
  },
];

export default {
  Bilag,
};
